import * as $ from "jquery";
import * as Cookies from "js-cookie";

//.AspNetCore.Cookies

class LoginStatus {
    public isLoggedIn: boolean;
    public expirationTime: number;
    public username: String;
}

interface AuthHandler {
    (loggedIn : boolean, isAdmin : boolean) : void;
}

class AuthHelper {
    
    static authCacheName = "authCache";
    
    static loggedIn : boolean;
    static admin : boolean;
    static callbacks : Array<AuthHandler> = [];

    static setupPage() {
        let cachedStatus = this.getCachedStatus();
        if (cachedStatus == null) {
            $.get("/Identity/Status")
                .done((data) => { this.handleStatusCheck(data) })
                .fail(this.showNoAuthState);
            return;
        }
        this.setupPageWithStatus(cachedStatus);
    }

    static setupPageWithStatus(status : LoginStatus) {
        if (status.isLoggedIn) {
            this.showAuthState(status);
            this.loggedIn = true;
        }
        else {
            this.showNoAuthState();
        }
        
        this.admin = true;
        
        for (var i = 0; i < this.callbacks.length; i++) {
            this.callbacks[i](this.loggedIn, this.admin);
        }
        
        this.callbacks = null;
    }

    static getCachedStatus() : LoginStatus {
        let cache = sessionStorage.getItem(AuthHelper.authCacheName);
        if (cache == null) {
            return null;
        }
        let cachedStatus : LoginStatus = JSON.parse(cache);
        if (cachedStatus.expirationTime < Date.now()) {
            AuthHelper.clearAuthCache();
            return null;
        }
        return cachedStatus;
    }

    static showNoAuthState() {
        $("#navLoggedIn").css("display", "none");
        $("#navLogin").css("display", "");
    }

    private static showAuthState(status: LoginStatus) {
        $("#navLogin").css("display", "none");
        $("#navLoggedInUser").text("@" + status.username);
        $("#navLoggedIn").css("display", "");
    }

    // @ts-ignore
    static handleStatusCheck(data) {
        let status = new LoginStatus();
        status.isLoggedIn = data.loggedIn == true;
        status.expirationTime = Date.now() + 1000 * 60 * 5;
        if (status.isLoggedIn) {
            status.username = data.name;
        }
        sessionStorage.setItem(AuthHelper.authCacheName, JSON.stringify(status));
        this.setupPageWithStatus(status);
    }
    
    static clearAuthCache() {
        sessionStorage.removeItem(AuthHelper.authCacheName);
    }
    
    static registerAuthHandler(handler : AuthHandler) {
        if (this.callbacks == null) {
            // Already set up and good to go.
            handler(this.loggedIn, this.admin);
        }
        else {
            this.callbacks.push(handler);
        }
    }
}

$(function () {
    if (!window.location.pathname.toLocaleLowerCase().startsWith('/identity')) {
        AuthHelper.setupPage();
    } else {
        AuthHelper.clearAuthCache();
    }
});

export { AuthHelper };
