import * as $ from "jquery";

class PageEditHandler {
    private containerElem: JQuery<HTMLElement>;
    private addButton: JQuery<HTMLElement>;
    private table: JQuery<HTMLElement>;
    
    constructor(initialConfig: object[], containerSelector: string, addButtonSelector: string) {
        this.containerElem = $(containerSelector);
        this.addButton = $(addButtonSelector);
        this.table = this.populateRows(initialConfig);
        let self = this;
        
        $(addButtonSelector).on("click", this,function () {
           self.createPhotoRow(self.table, {photoId:""}); 
        });
    }
    
    
    getResult() : any[] {
        var self = this;
        var result: any[] = [];
        this.table.find("tr").each((index, element) => {
            var row = $(element);
            const input = row.find("input[data-type='photo']");
            var photoId = input.val();
            result.push({"photoId": photoId});
        });
        return result;
    }
    
    populateRows(initialConfig: object[]) : JQuery<HTMLElement> {
        this.containerElem.append("<table id='pagedataTable'/>");
        var table = $("#pagedataTable");
        initialConfig.forEach(row => {
            this.createPhotoRow(table, row);
        });
        this.containerElem.append(table);
        return table;
    }
    
    createPhotoRow(table: JQuery<HTMLElement>, row: any) {
        var tr = $(document.createElement("tr"));
        tr.append(`<td><td><input type='text' size='40' value='${row.photoId}' data-type='photo'></td><td><a class="btn btn-primary btn-sm upbutton">Up</a></td><td><a class="btn btn-primary btn-sm downbutton">Down</a><td><a class="btn btn-danger btn-sm deletebutton">Delete</a></td> `)
        table.append(tr);
        
        tr.find("a").on("click", function (){
           var b = $(this);
           var row = b.parents("tr:first");
           if (b.hasClass("upbutton")) {
               row.insertBefore(row.prev());
           } else if (b.hasClass("downbutton")) {
               row.insertAfter(row.next())
           } else if (b.hasClass("deletebutton")) {
               row.remove();
           }
        });
    }
}

export {
    PageEditHandler
};
