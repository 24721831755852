
import * as Cookies from "js-cookie";
import * as $ from "jquery";

class PhotoNav {
    static clearRail() {
        Cookies.remove("rail");
    }
    
    static switchToRail(type: string, key: string): string {
        var railName = type + "/" + key;
        Cookies.set("rail", railName, {expires: 7});
        return railName;
    }

    static switchAndGoNext(currentPhoto: string, type: string, key: string) {
        let rail = this.switchToRail(type, key);
        $(window).attr("location", "/Photo/NextPhoto?photo=" + currentPhoto + "&rail=" + rail);
    }

    static switchAndGoPrev(currentPhoto:string, type: string, key: string) {
        let rail = this.switchToRail(type, key);
        $(window).attr("location", "/Photo/PreviousPhoto?photo=" + currentPhoto + "&rail=" + rail);
    }

    static resolveNav(photoId: string) {
        let currentRailJoined = Cookies.get("rail");
        var railParts: string[] = [];
        if (currentRailJoined) {
            railParts = currentRailJoined.split("/");
        }
        var currentRail : string;
        
        if (railParts.length != 2) {
            // Invalid rail... it'll be random if it gets to the server anyway.
            currentRail = undefined;
        }
        else {
            currentRail = railParts[0].toLowerCase();
        }

        this.manageRandomRail(currentRail, photoId);
        
        if (!currentRail) {
            currentRail = "random";
        }

        this.prefetchNavLinks(photoId);
        if (currentRail == "random") {
            // $("#nextLink").text("Next (random)"); // TODO: Add this text.
            $("#nextDropRandom").addClass("active");
            $("#nextDropTag").addClass("disabled");
        }
        if (currentRail == "tag") {
            // $("#nextLink").text("Next (tag)"); // TODO: Add this text.
            $("#nextDropTag").addClass("active");
            // TODO: Make the tag name in the link match.
        }
        if (currentRail == "batch") {
            // $("#nextLink").text("Next (batch)"); // TODO: Add this text.
            $("#nextDropBatch").addClass("active");
            $("#nextDropTag").addClass("disabled");
        }
    }

    private static prefetchNavLinks(photoId: string) {
        $.get("/photo/getnav?photo=" + photoId, function (data, status) {
            if (status == "success") {
                $("#nextLink").attr("href", data.next);
                $("#previousLink").attr("href", data.prev);
            }
        });
    }
    
    private static manageRandomRail(rail: string, photoId: string) {
        const randomCookieName = "randomrailphoto";
        if (rail == "random") {
            Cookies.set(randomCookieName, photoId);
        } else {
            const randomPhotoRail = Cookies.get(randomCookieName);
            if (randomPhotoRail) {
                $("#nextDropRandom").attr("href", `/Photo/NextPhoto?photo=${randomPhotoRail}&rail=random/r1`)
            }
        }
    }
}

export { PhotoNav }

