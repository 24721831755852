import * as $ from "jquery";
import { AuthHelper } from "./AuthHelper";

declare var globalPhotoId : any;

class Suggestions {
    setupPage(loggedIn: boolean, admin: boolean) {
        let staticTitle = $("#staticTitle");
        
        staticTitle.on("click", t => {
            $("#originalTitleContainer").css("display", "none");
            $("#editTitleContainer").css("display", "");
            $("#titleSuggestionSubmit").on("click", this.submitTitleSuggestion)
        })
    }    
    
    submitTitleSuggestion() {
        console.log('Submitting form...');
        $.ajax({
            type: 'POST',
            url: '/Photo/SuggestTitle',
            contentType: 'application/x-www-form-urlencoded',
            data: {
                photoId: globalPhotoId,
                suggestedTitle: $("#titleSuggestion").val()
            },
            success: function (result) {
                console.log('Data received: ');
                console.log(result);
                const titleSuggestionSubmit = $("#titleSuggestionSubmit");
                titleSuggestionSubmit.text("Done!");
                titleSuggestionSubmit.addClass("btn-outline-primary");
                titleSuggestionSubmit.removeClass("btn-outline-danger");
            },
            error: function () {
                const titleSuggestionSubmit = $("#titleSuggestionSubmit");
                titleSuggestionSubmit.text("Error");
                titleSuggestionSubmit.removeClass("btn-outline-primary");
                titleSuggestionSubmit.addClass("btn-outline-danger");
            }

        });
    }
}

$(function () {
    var suggesstions = new Suggestions();
    AuthHelper.registerAuthHandler((loggedIn, isAdmin) => {
       suggesstions.setupPage(loggedIn, isAdmin);
    }) 
});

export { Suggestions }